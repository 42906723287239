export const toolsData = [
  {
    name: "Tools",
    size: 40, // dropdown height
    subItems: [
      {
        text: "Coin Flip Simu",
        subItems: [
          {
            text: "Coin Flip",
          },
          {
            text: "Intuition Test",
          },
          {
            text: "Luck Test",
          },
        ],
      },
      {
        text: "Dice Roll Simu",
        subItems: [
          {
            text: "D4 Dice",
          },
          {
            text: "D6 Dice",
          },
          {
            text: "D8 Dice",
          },
          {
            text: "D10 Dice",
          },
          {
            text: "D12 Dice",
          },
          {
            text: "D20 Dice",
          },
        ],
      },
      {
        text: "Tarot Flip Simu",
      },
      {
        text: "Number Flip Simu",
      },
    ],
  },
]
