import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowDropDown } from "@styled-icons/material-rounded"
import { Box, Text } from "."
import withLocation from "../HOC/withLocation"

const DropdownOuter = styled(Box)`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    min-width: 28rem;
    height: ${props => `${props.size}px`};
    -webkit-tap-highlight-color: transparent; 
`

const DropdownMain = styled(Box)`
  height: ${props => `${props.size}px`};
  user-select: none;
  position: absolute;
  width: 100%;
  & > div > div {
    &:nth-child(1) {
      background-color: transparent;
      padding: 0rem;
    }
    &:nth-child(2) {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 2px;
      & > div {
        z-index: 99;
        &:nth-child(1) {
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          & > div:nth-child(1) {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
          }
        }
        &:nth-last-child(1) {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          & > div {
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            &:nth-child(2) {
              border-bottom-left-radius: 2px;
              border-bottom-right-radius: 2px;
            }
            & > div:nth-last-child(1) {
              border-bottom-left-radius: 2px;
              border-bottom-right-radius: 2px;
            }
          }
        }
      }
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  font-size: 1.8rem;
  width: 100%;
  & > div:nth-child(2) {
    box-shadow: 0px -1px 2px #00000029;
  }
`;

const DropdownButton = styled.div`
  background-color: rgb(255,255,255);
  color: #3A3A3A;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border: none;
  width: 100%;
  &:hover {
    ${props => (props.hover &&
    `color: rgb(255,255,255);
    background-color: ${props.theme.colors.accent};
    `)};
  }
  gap: 0.8rem;
  display: flex;
  align-items: center;
  ${props => (props.show &&
    `border-bottom-left-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
    `)};
`;

const DropdownMenu = styled.div`
  top: 100%;
  left: 0;
  z-index: 1;
  display: ${props => (props.show ? 'block' : 'none')};
  width: 100%;
  background-color: ${props => props.theme.colors.accent};
`;

const DropdownItem = styled.div`
  position: relative;
  color: #3A3A3A;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${props =>
    props.isMain
      ?
      `gap: 0.8rem;
      padding: 0.65rem 0rem 0.65rem 1rem;
      background-color: rgb(255,255,255);
      &:hover {
        color: rgb(255,255,255);
        background-color: ${props.theme.colors.accent};
      }`
      :
      `gap: 3rem;
      padding: 0.65rem 1rem 0.65rem 3.6rem;
      background-color: rgb(242, 242, 242);
      &:hover {
        color: #FFFFFF;
        background-color: ${props.theme.colors.accent};
        & > img {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(147deg) brightness(106%) contrast(104%);
        }
      }
      & > img {
        filter: invert(19%) sepia(18%) saturate(0%) hue-rotate(257deg) brightness(94%) contrast(87%);
      }
      `
  };
`;

const DropdownAngleDownButton = styled(Box)`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
`

const NestedDropdownItem = ({ id, text, isMain = false, setChoice }) => {
  return (
    <DropdownItem key={id} isMain={isMain}
      onClick={() => setChoice(text)}
    >
      {text}
    </DropdownItem>
  )
}

const NestedDropdown = ({ children, id, data, content, isShow = false, isNested = false, refs, setChoice }) => {
  const refBox = refs.refBox
  const refButton = refs.refButton

  const [show, setShow] = useState(isShow)
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return
    const handleClick = (event) => {
      let buttonClick = true
      let menuBoxClick = true
      if (!refButton.current || !refButton.current.contains(event.target)) {
        buttonClick = false
      }
      if (!refBox.current || !refBox.current.contains(event.target)) {
        menuBoxClick = false
      }
      if (!buttonClick && !menuBoxClick && !isNested) {
        setShow(false)
      }
    }
    window.addEventListener("mousedown", handleClick)
    window.addEventListener("touchstart", handleClick);
    return () => {
      window.removeEventListener("mousedown", handleClick)
      window.addEventListener("touchstart", handleClick);
    }
  }, [])

  return (
    <DropdownContainer key={id} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} size={data.size}>
      <DropdownButton ref={data.name ? refButton : null} onClick={() => !isNested ? setShow(!show) : setChoice(data.text)} hover={data.name ? false : hover} show={show}>
        {content}
      </DropdownButton>
      <DropdownMenu ref={data.name ? refBox : null} show={show}
        onClick={() => {
          if (!isNested) {
            setShow(false)
          }
        }}
      >{children}</DropdownMenu>
    </DropdownContainer>
  );
};

const Dropdown = props => {
  const menuItems = props.options
  const onChange = props.onChange
  const name = props.name

  const refBox = useRef();
  const refButton = useRef();
  // const choice = props.choice;
  // const setChoice = props.setValue;
  const [choice, setChoice] = useState(props.value ? props.value : menuItems[0].subItems[0].text);

  useEffect(() => {
    onChange(choice)
  }, [choice])

  return (
    <DropdownOuter size={menuItems[0].size}>
      <DropdownMain size={menuItems[0].size}>
        <NestedDropdown
          data={menuItems[0]}
          id={`${name}-select-button`}
          content={
            <>
              <Box
                backgroundColor="#F2F2F2"
                width="100%"
                justifyContent="center"
                padding="5px 10px"
                borderRadius="2px"
                height={`${menuItems[0].size}px`}
              >
                <Text fontWeight="600">{choice}</Text>
              </Box>
              <DropdownAngleDownButton>
                <ArrowDropDown size={33}
                  style={{
                    right: `0px`,
                    position: `absolute`,
                  }}
                />
              </DropdownAngleDownButton>
            </>
          }
          refs={{ refBox, refButton }}
        >
          {menuItems[0].subItems.map((item, index) => {
            return (
              <>
                {item.subItems ? (
                  <NestedDropdown id={`${name}-select-item-${index}`} data={item} isShow={true} isNested={true} refs={{ refBox, refButton }}
                    content={<Text>{item.text}</Text>}
                    setChoice={setChoice}
                  >
                    {item.subItems.map((subItem, subIndex) => {
                      return (
                        <NestedDropdownItem id={`${name}-select-item-${index}-${subIndex}`} text={subItem.text} setChoice={setChoice} />
                      )
                    })}
                  </NestedDropdown>
                ) : (
                  <NestedDropdownItem id={`${name}-select-item-${index}`} text={item.text} isMain={true} setChoice={setChoice} />
                )}
              </>
            )
          })}
        </NestedDropdown>
      </DropdownMain>
    </DropdownOuter>
  );
};

export default withLocation(Dropdown);